<template>
    <v-card elevation="2" outlined>
        <v-card-title>
            <v-col
                cols="12"
                md="9"
            >
                Members
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
            </v-col>
             
        </v-card-title>
        <v-card-text>
           
            <v-data-table
                :headers="headers"
                :items="compo"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:item.pnl="{ item }">
                    <div
                        :class="[item.pnl>0.0?'green--text':'red--text']"
                        class="d-flex "
                    >
                        {{ Number(item.pnl).toLocaleString() }} €
                    </div>
                </template>
                <template v-slot:item.relative_pnl="{ item }">
                    <div
                        :class="[item.pnl>0.0?'green--text':'red--text']"
                        class="d-flex "
                    >
                        {{ Number(item.relative_pnl).toLocaleString() }} %
                    </div>
                </template>
                <template v-slot:item.invested_amount="{ item }">
                    <div
                        class="d-flex"
                    >
                        {{ Number(item.invested_amount).toLocaleString() }} €
                    </div>
                </template>
                <template v-slot:item.current_amount="{ item }">
                    <div
                        class="d-flex"
                    >
                        {{ Number(item.current_amount).toLocaleString() }} €
                    </div>
                </template>
                <template v-slot:item.weight="{ item }">
                    <div
                        class="d-flex"
                    >
                        {{ Number(item.weight).toLocaleString() }} %
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'PortfolioAssets',
        computed: {
            ...mapGetters('dashboard', {
                members:"members",
                compo_ptf_value:'compo_ptf_value',
            }),
            compo(){
                let i=0;
                return this.members
                        .map((asset)=>{
                            i=i+1;
                            return {
                                'id':i,
                                'member':asset.member,
                                "invested_amount": this.to_locale(asset.invested,1),
                                "current_amount": this.to_locale(asset.amount,1),
                                "pnl": this.to_locale(asset.amount-asset.invested,1),
                                "relative_pnl": this.to_locale(asset.amount/asset.invested*100.0-100.0,1),
                                "weight" : this.to_locale(asset.amount/this.compo_ptf_value*100,1)

                            }
                        })
            },
            headers(){
                return [
                    {text:"#", value:"id"},
                    {text:"Name", value:"member"},
                    {text:"Invested Amount", value:"invested_amount"},
                    {text:"Current Amount", value:"current_amount"},
                    {text:"P&L", value:"pnl"},
                    {text:"Rel. P&L", value:"relative_pnl"},
                    {text:"Weight", value:"weight"},
                ]
                
            }
            
        },
        data: () => ({
        }),
        methods: {
            to_locale(dbl, factor=1.0){
                return Math.round(dbl*100*factor)/100;
            },

        },
    }
</script>
