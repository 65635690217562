<script>
  import { Line, mixins } from 'vue-chartjs'
  import { mapGetters } from 'vuex'

  export default {
    extends: Line,
    computed: {
        ...mapGetters('dashboard', {
            vl_hist_ptf_bmk:"vl_hist_ptf_bmk",
            params_begin_date:"params_begin_date",
        }),
    },
    data () {
      return {
        options: {
            elements: {
                point:{
                    radius: 0
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: false
                    },
                    gridLines: {
                        display: true
                    }
                },
                {
                    id: 'z',
                    type: 'linear',
                    position: 'right',
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: false
                    }
                }],
                xAxes: [ {
                    gridLines: {
                        display: false
                    }
                }]
            },
            legend: {
                display: true
            },
            responsive: true,
            maintainAspectRatio: false
            }
      }
    },
    props: ["chartData"],
    mixins: [mixins.reactiveProp],
    mounted () {
        this.renderChart(this.chartData, this.options)
    },
    
  }
</script>