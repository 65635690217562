<template>
    <v-card elevation="2" outlined>
        <v-card-title>Highlights</v-card-title>
        <v-card-text>
            <v-simple-table>
                <template>
                
                <tbody>
                    <tr>
                        <td class="font-weight-black">
                            <v-icon>mdi-update</v-icon>
                            <span>&nbsp; Current Date</span>
                        </td>
                        <td>{{compo_last_date}}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <v-icon>mdi-wallet-outline</v-icon>
                            <span>&nbsp; Current Ptf Value</span>
                            </td>
                        <td>{{Number(compo_ptf_value).toLocaleString()}} €</td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; Previous Ptf Value</span>
                        </td>
                        <td>
                            {{Number(compo_ptf_value_previous).toLocaleString()}} €
                            </td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; Daily P/L</span>
                        </td>
                        <td :class="pnl_color">
                            {{Number(pnl_portfolio).toLocaleString()}} €
                            &nbsp; ({{Number(pnl_portfolio_pct).toLocaleString()}} %)
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; Number of assets</span>
                        </td>
                        <td >
                            {{ compo_ptf_nb_assets }}
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; Cash Amount</span>
                        </td>
                        <td>
                            {{Number(compo_cash_value).toLocaleString()}} €
                            <span 
                                v-if="daily_cash_amount!=0"
                                :class="pnl_cash_color"
                            >
                                &nbsp; ({{Number(daily_cash_amount).toLocaleString()}} €)
                            </span>
                            <span v-else>
                                &nbsp; ({{Number(pct_cash).toLocaleString()}} %)
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; Invested amount</span>
                        </td>
                        <td >
                            {{Number(moves_invested_amount).toLocaleString()}} €

                            <span 
                                :class="global_pnl_color"
                            >
                                &nbsp; ({{ Number(global_pnl).toLocaleString() }} €)
                            </span>
                            
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; Value / Invested </span>
                        </td>
                        <td 
                            :class="ptf_vl_perf_color"
                        >
                            {{Number(ptf_vl_perf).toLocaleString()}} %
                          
                            
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-black">
                            <span>&nbsp; P/L since inception </span>
                        </td>
                        <td 
                            :class="ptf_vl_pl_color"
                        >
                            {{Number(vl_last_ptf).toLocaleString()}} %  
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'PortfolioState',

        computed: {
            ...mapGetters('dashboard', {
                compo_last_date:'compo_last_date',
                compo_ptf_value:'compo_ptf_value',
                compo_ptf_value_previous:"compo_ptf_value_previous",
                compo_ptf_nb_assets:"compo_ptf_nb_assets",
                compo_cash_value: "compo_cash_value",
                compo_cash_value_previous:"compo_cash_value_previous",
                moves_invested_amount:"moves_invested_amount",
                vl_last_ptf:"vl_last_ptf"
            }),
            pnl_portfolio(){
                return this.compo_ptf_value-this.compo_ptf_value_previous;
            },
            pnl_portfolio_pct(){
                return Math.round(this.pnl_portfolio/this.compo_ptf_value*10000.0)/100.0;
            },
            pnl_color(){
                if(this.pnl_portfolio>0)
                    return "green--text";
                return "red--text"; 
            },
            pnl_cash_color(){
                if(this.daily_cash_amount>0)
                    return "green--text";
                return "red--text"; 
            },
            daily_cash_amount(){
                return this.compo_cash_value - this.compo_cash_value_previous;
            },
            pct_cash(){
                return Math.round(this.compo_cash_value/this.compo_ptf_value*10000.0)/100;
            },
            global_pnl(){
                return this.compo_ptf_value-this.moves_invested_amount;
            },
            global_pnl_color(){
                if(this.global_pnl>0)
                    return "green--text";
                return "red--text"; 
            },
            ptf_vl_perf_color(){
                if(this.ptf_vl_perf>0)
                    return "green--text";
                return "red--text"; 
            },
            ptf_vl_perf(){
                return Math.round((this.compo_ptf_value/this.moves_invested_amount-1)*10000)/100;
            },
            ptf_vl_pl_color(){
                if(this.vl_last_ptf>0)
                    return "green--text";
                return "red--text"; 
            },
        },
        data: () => ({
            
        }),
    }
</script>
