<template>
    <v-card elevation="2" outlined>
        <v-card-title>Value of the portfolio</v-card-title>
        <v-card-text>
            <PortfolioChartVl />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import PortfolioChartVl from './PortfolioChartVl'
    export default {
        name: 'PortfolioChartVlCard',
        components:{
            PortfolioChartVl
        },
        computed: {
            ...mapGetters('dashboard', {
            })
        },
        data: () => ({
            
        }),
    }
</script>
