<template>
  <v-app>
    <NavBar     /> 
    <AppBar     /> 
    <v-main
      class="indigo lighten-5"
    >
    <div v-if="!is_identified" style="margin-top:5px">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card >
              <v-card-text>
                <v-form @submit="make_login_auth">
                    <v-text-field
                      prepend-icon="mdi-key"
                      label="Password"
                      type="password"
                      v-model="password"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn 
                      block color="primary" 
                      @click.native="make_login_auth" 
                      :loading="loging_process"
                      >
                      Se connecter
                    </v-btn>
                    <div>&nbsp;</div>
                </v-form>
              </v-card-text>
          </v-card>
        </v-flex>
        </v-layout>
      </v-container>
      </div>
      <router-view v-else-if="is_connected"></router-view>
      <div v-else style="margin-top:5px">
        <v-progress-linear
          indeterminate
          color="primary"
        ></v-progress-linear>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/common/NavBar'
import AppBar from './components/common/AppBar'

import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    NavBar,
    AppBar
  },
  computed: {
    ...mapGetters('dashboard', {
        is_connected:"is_connected",
        is_identified:"is_identified"
    }),
  },

  data: () => ({
    password:"",
    loging_process:false
  }),
  mounted(){
      if(this.is_identified)
        this.$store.commit('dashboard/connect_to_dashboard');
    },
  methods:{
      make_login_auth(){
        this.$store.commit('dashboard/make_identification', {
                is_identified:this.password
            });
        if(this.is_identified)
          this.$store.commit('dashboard/connect_to_dashboard');
      },
  }

};
</script>

