<template>
  <div 
    style="font: 1.5rem 'HighTide', sans-serif"
    :style="custom_style"
  >
    FightingBulls
  </div>
</template>

<script>
  export default {
    name: 'Logo',
    props:["custom_style"],
    data: () => ({
        name: 'FightingBulls'
    }),
  }
</script>

<style>
  @font-face {
    font-family: "HighTide";
    src: local("HighTide"),
    url(./HighTide.ttf) format("truetype");
  }
</style>

