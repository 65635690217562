var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"9"}},[_vm._v(" Components ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-switch',{attrs:{"label":"Local performance"},model:{value:(_vm.local_switch),callback:function ($$v) {_vm.local_switch=$$v},expression:"local_switch"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.compo,"items-per-page":5},scopedSlots:_vm._u([{key:"item.eod",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.eod>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.eod)+" % ")])]}},{key:"item.eow",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.eow>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.eow)+" % ")])]}},{key:"item.eom",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.eom>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.eom)+" % ")])]}},{key:"item.eoy",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.eoy>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.eoy)+" % ")])]}},{key:"item.m3",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.m3>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.m3)+" % ")])]}},{key:"item.m6",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.m6>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.m6)+" % ")])]}},{key:"item.y1",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.y1>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.y1)+" % ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }