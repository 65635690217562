var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" Components ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-switch',{attrs:{"label":"See Fundamentals"},model:{value:(_vm.fundamental_switch),callback:function ($$v) {_vm.fundamental_switch=$$v},expression:"fundamental_switch"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.compo,"items-per-page":5},scopedSlots:_vm._u([{key:"item.isin",fn:function(ref){
var item = ref.item;
return [(item.logo_url!='')?_c('span',[_c('img',{staticStyle:{"height":"20px"},attrs:{"src":item.logo_url,"alt":""}})]):_vm._e(),_vm._v(" "+_vm._s(item.isin)+" ")]}},{key:"item.market_pnl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",class:[item.market_pnl>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.market_pnl)+" % ")])]}},{key:"item.current_price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(Number(item.current_price).toLocaleString())+" € ")])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(Number(item.weight).toLocaleString())+" % ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }