<template>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
    >

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Menu
          </v-list-item-title>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
    
      <v-list
        dense
        nav
        v-if="is_connected"
      >
        <v-list-item
            v-for="tab in params_tabs"
            :key="tab.name"
            link
            :to="tab.link"
        >
          <v-list-item-icon>
            <v-icon>{{tab.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{tab.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'NavBar',
    components: {
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters('dashboard', {
        is_connected:"is_connected",
        get_params:"get_params",
        params_tabs:"params_tabs"
      }),
      clipped:{
        get(){
            return this.get_params.clipped;
        },
        set(val){
            this.$store.commit('dashboard/set_params', {
                key:"clipped",
                value: val
            })
        }
      },
      drawer:{
        get(){
            return this.get_params.drawer;
        },
        set(val){
            this.$store.commit('dashboard/set_params', {
                key:"drawer",
                value: val
            })
        }
      }
    },
  }
</script>
