<template>
    <v-card elevation="2" outlined>
        <v-card-title>Last Performance by Year</v-card-title>
        <v-card-text>
            <v-simple-table>
                <template>
                <thead>
                    <tr>
                        <th class="font-weight-black text-center">Year</th>
                        <th class="font-weight-black text-center">Portfolio</th>
                        <th class="font-weight-black text-center">Benchmark</th>
                        <th class="font-weight-black text-center">Active</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="perf in perf_yearly" :key="perf.year">
                        <td class="font-weight-black">
                            <span>{{perf.year}}</span>
                        </td>
                        <td :class="[perf.ptf>0.0?'green--text':'red--text']">
                            {{ to_locale(perf.ptf) }} %
                        </td>
                        <td :class="[perf.bmk>0?'green--text':'red--text']">
                            {{ to_locale(perf.bmk) }} %
                        </td>
                        <td :class="[perf.alpha>0?'green--text':'red--text']">
                            {{ to_locale(perf.alpha) }} %
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'PortfolioPerformance',

        computed: {
            ...mapGetters('dashboard', {
                perf_yearly: "perf_yearly"
            })
        },
        data: () => ({
            
        }),
        methods:{
            rounding(pct){
                return Math.round(pct*10000)/100;
            },
            to_locale(pct){
                return Number(this.rounding(pct)).toLocaleString();
            }
        }
    }
</script>
