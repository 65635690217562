var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"9"}},[_vm._v(" Components ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-switch',{attrs:{"label":"See dividends"},model:{value:(_vm.div_switch),callback:function ($$v) {_vm.div_switch=$$v},expression:"div_switch"}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.compo,"items-per-page":5},scopedSlots:_vm._u([{key:"item.market_pnl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",class:[item.market_pnl>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.market_pnl)+" % ")])]}},{key:"item.total_pnl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",class:[item.total_pnl>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(item.total_pnl)+" % ")])]}},{key:"item.buy_price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(Number(item.buy_price).toLocaleString())+" € ")])]}},{key:"item.current_price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(Number(item.current_price).toLocaleString())+" € ")])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(Number(item.weight).toLocaleString())+" % ")])]}},{key:"item.dividend_pnl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(Number(item.dividend_pnl).toLocaleString())+" % ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }