<template>
    <v-card elevation="2" outlined>
        <v-card-title>
            <v-col
                cols="9"
            >
                Components
            </v-col>
            <v-col
                cols="3"
            >
                <v-switch
                    v-model="div_switch"
                    :label="`See dividends`"
                ></v-switch>
            </v-col>
             
        </v-card-title>
        <v-card-text>
           
            <v-data-table
                :headers="headers"
                :items="compo"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:item.market_pnl="{ item }">
                    <div
                        :class="[item.market_pnl>0.0?'green--text':'red--text']"
                        class="d-flex justify-center"
                    >
                        {{ item.market_pnl }} %
                    </div>
                </template>
                <template v-slot:item.total_pnl="{ item }">
                    <div
                        :class="[item.total_pnl>0.0?'green--text':'red--text']"
                        class="d-flex justify-center"
                    >
                        {{ item.total_pnl }} %
                    </div>
                </template>
                <template v-slot:item.buy_price="{ item }">
                    <div
                        class="d-flex justify-center"
                    >
                        {{ Number(item.buy_price).toLocaleString() }} €
                    </div>
                </template>
                <template v-slot:item.current_price="{ item }">
                    <div
                        class="d-flex justify-center"
                    >
                        {{ Number(item.current_price).toLocaleString() }} €
                    </div>
                </template>
                <template v-slot:item.weight="{ item }">
                    <div
                        class="d-flex justify-center"
                    >
                        {{ Number(item.weight).toLocaleString() }} %
                    </div>
                </template>
                <template v-slot:item.dividend_pnl="{ item }">
                    <div
                        class="d-flex justify-center"
                    >
                        {{ Number(item.dividend_pnl).toLocaleString() }} %
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'PortfolioAssets',
        computed: {
            ...mapGetters('dashboard', {
                compo_current:"compo_current",
                compo_ptf_value:'compo_ptf_value',
                moves_dividends:'moves_dividends'
            }),
            compo(){
                let i=0;
                return this.compo_current
                        .map((asset)=>{
                            i=i+1;
                            return {
                                'id':i,
                                'name':asset.name,
                                'isin': asset.isin,
                                "buy_price": this.to_locale(asset.pru_eur*asset.quantity,1),
                                "current_price": this.to_locale(asset.valo,1),
                                "market_pnl": this.to_locale(asset.valo/(asset.pru_eur*asset.quantity)-1,100),
                                "dividend_pnl": this.to_locale(this.getDividend(asset.isin)/asset.valo, 100),
                                "total_pnl": this.to_locale((this.getDividend(asset.isin)+asset.valo)/(asset.pru_eur*asset.quantity)-1, 100),

                                "weight":this.to_locale(asset.valo/this.compo_ptf_value,100),
                            }
                        })
            },
            headers(){
                if(this.div_switch)
                    return [
                        {text:"#", value:"id"},
                        {text:"ISIN", value:"isin"},
                        {text:"Name", value:"name"},
                        {text:"Buying Valuation (€)", value:"buy_price"},
                        {text:"Current Valuation (€)", value:"current_price"},
                        {text:"Market P&L (%)", value:"market_pnl"},
                        {text:"Dividend P&L (%)", value:"dividend_pnl"},
                        {text:"Total P&L (%)", value:"total_pnl"},
                        {text:"Weight (%)", value:"weight"},
                    ]
                return [
                        {text:"#", value:"id"},
                        {text:"ISIN", value:"isin"},
                        {text:"Name", value:"name"},
                        {text:"Buying Valuation (€)", value:"buy_price"},
                        {text:"Current Valuation (€)", value:"current_price"},
                        {text:"Market P&L (%)", value:"market_pnl"},
                        {text:"Weight (%)", value:"weight"},
                    ]
            }
        },
        data: () => ({
            div_switch:false
        }),
        methods: {
            getDividend(isin){
                if(this.moves_dividends[isin]!=undefined)
                    return this.moves_dividends[isin];
                return 0.0;
            },
            to_locale(dbl, factor=1.0){
                return Math.round(dbl*100*factor)/100;
            }
        },
    }
</script>
