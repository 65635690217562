<template>
    <div>
        <v-card elevation="2" outlined>
            <v-card-title>
                <v-col
                    cols="12"
                    md="6"
                >
                    Breakdown by {{breakdown_type}}
                </v-col>
            </v-card-title>
            <v-card-text>
                <ChartPie 
                    :chartData="chartData"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChartPie from './ChartPie'
    export default {
        name: 'PieCard',
        components:{
            ChartPie
        },
        computed: {
            ...mapGetters('dashboard', {
                compo_current:"compo_current",
                compo_ptf_value: "compo_ptf_value",
                compo_descriptives:"compo_descriptives",
                compo_other_descriptives:"compo_other_descriptives"
            }),
            transform_data(){
                const groupBy = (arr, key, value) => {
                    const initialValue = {};
                    return arr.reduce((acc, cval) => {
                        const myAttribute = cval[key];
                        if(acc[myAttribute]!=undefined)
                            acc[myAttribute] =acc[myAttribute] +cval[value];
                        else
                            acc[myAttribute] =+cval[value];
                        return acc;
                    }, initialValue);
                };
                console.log(this.compo_current);
                let breakdown=this.compo_current
                        .map((item)=>{
                            let isin=item.isin;
                            let valo=item.valo;
                            let factor="N/A";
                            if(this.compo_descriptives[isin]!=undefined)
                                factor=this.compo_descriptives[isin][this.breakdown_type];
                            if(factor==undefined)
                                if(this.compo_other_descriptives[isin]!=undefined)
                                    factor=this.compo_other_descriptives[isin][this.breakdown_type];
                            return {
                                'amount': valo,
                                'isin': isin,
                                'factor': factor
                            };
                        });
                breakdown=groupBy(breakdown, "factor", "amount");
                let b=[];
                for(let key in breakdown){
                    b.push({"factor":key, 'valo':breakdown[key]})
                }
                breakdown=b
                            .sort((a,b)=>{
                                return a.valo<b.valo?1:-1;
                            });
                return breakdown;
            },
            labels(){
                return this.transform_data.map((item)=>item.factor);
            },
            data(){
                return this.transform_data.map((item)=>Math.round(item.valo/this.compo_ptf_value*1000)/10.0);
            },
            chartData(){
                return {
                    labels: this.labels,
                    datasets: [{
                        label: '',
                        data: this.data,
                        hoverOffset: 4,
                        backgroundColor: [
                            "#0275d8",
                            "#5cb85c",
                            "#5bc0de",
                            "#f0ad4e",
                            "#d9534f",
                            "#292b2c",
                            "#f7f7f7",
                            "#ff0000",
                            "#ffa500",
                            "#ffff00",
                            "#008000",
                            "#0000ff",
                            "#4b0082",
                            "#ee82ee",
                            "#6b5b95",
                            "#feb236",
                            "#d64161",
                            "#ff7b25",
                            "#a2b9bc",
                            "#b2ad7f",
                            "#878f99",
                            "#6b5b95",
                            "#d6cbd3",
                            "#eca1a6",
                            "#bdcebe"
                        ],
                    }]
                }
            }
        },
        props:["breakdown_type"],
        data: () => ({
        }),
        methods:{
        }
    }
</script>
