<template>
    <v-card elevation="2" outlined>
        <v-card-title>
            <v-col
                cols="12"
                md="9"
            >
                Components
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <v-switch
                    v-model="fundamental_switch"
                    :label="`See Fundamentals`"
                ></v-switch>
            </v-col>
             
        </v-card-title>
        <v-card-text>
           
            <v-data-table
                :headers="headers"
                :items="compo"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:item.isin="{ item }">
                    <span v-if="item.logo_url!=''">
                        <img :src="item.logo_url" alt="" style="height:20px"/>
                        </span>
                    {{ item.isin }}
                </template>
                <template v-slot:item.market_pnl="{ item }">
                    <div
                        :class="[item.market_pnl>0.0?'green--text':'red--text']"
                        class="d-flex justify-center"
                    >
                        {{ item.market_pnl }} %
                    </div>
                </template>
                <template v-slot:item.current_price="{ item }">
                    <div
                        class="d-flex justify-center"
                    >
                        {{ Number(item.current_price).toLocaleString() }} €
                    </div>
                </template>
                <template v-slot:item.weight="{ item }">
                    <div
                        class="d-flex justify-center"
                    >
                        {{ Number(item.weight).toLocaleString() }} %
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'PortfolioAssets',
        computed: {
            ...mapGetters('dashboard', {
                compo_current:"compo_current",
                compo_ptf_value:'compo_ptf_value',
                compo_descriptives:"compo_descriptives",
                compo_other_descriptives:"compo_other_descriptives"
            }),
            compo(){
                let i=0;
                return this.compo_current
                        .map((asset)=>{
                            i=i+1;
                            let desc={
                                "industry":"-",
                                "sector":"-",
                                "region" : "-",
                                "country": "-",
                                "logo_url": "",
                                "market_cap_usd": 0.0,
                                "forward_pe":0.0,
                                "trailing_pe": 0.0,
                                "roe":0.0
                            };
                            let other_desc={
                                "style":"-",
                                "category":"-"
                            }
                            if(this.compo_descriptives[asset.isin]!=undefined){
                                desc=this.compo_descriptives[asset.isin];
                            }
                            if(this.compo_other_descriptives[asset.isin]!=undefined){
                                other_desc=this.compo_other_descriptives[asset.isin];
                            }
                            if(asset.isin=="LU0322252924" || asset.isin=="FR0013416716"){
                                desc["industry"]="ETF";
                                desc["sector"]="ETF";
                                desc["region"]="-";
                                desc["country"]="-";

                            }
                            if(asset.isin=="LU0322252924"){
                                desc["region"]="APAC";
                                desc["country"]="Vietnam";
                            }
                            return {
                                'id':i,
                                'name':asset.name,
                                'isin': asset.isin,
                                "current_price": this.to_locale(asset.valo,1),
                                "market_pnl": this.to_locale(asset.valo/(asset.pru_eur*asset.quantity)-1,100),
                                "weight":this.to_locale(asset.valo/this.compo_ptf_value,100),
                                "industry": desc["industry"],
                                "sector": desc["sector"],
                                "region": desc["region"],
                                "country": desc["country"],
                                "logo_url": desc["logo_url"],
                                "market_cap_usd": Math.round(desc["market_cap_usd"]/1000000000),
                                "forward_pe": desc["forward_pe"],
                                "trailing_pe": Math.round(desc["trailing_pe"]*10)/10,
                                "roe":Math.round(desc["roe"]*1000)/10,
                                "style": other_desc["style"],
                                "category_lynch":other_desc["category"]


                            }
                        })
            },
            headers(){
                if(this.fundamental_switch)
                    return [
                        {text:"#", value:"id"},
                        {text:"ISIN", value:"isin"},
                        {text:"Name", value:"name"},

                        {text:"Forward PE", value:"forward_pe"},
                        {text:"Trailing PE", value:"trailing_pe"},
                        {text:"ROE (%)", value:"roe"},

                        {text:"Market Cap (Md$)", value:"market_cap_usd"},
                        {text:"Current Valuation (€)", value:"current_price"},
                        {text:"Market P&L (%)", value:"market_pnl"},
                        {text:"Weight (%)", value:"weight"},
                    ]
                return [
                    {text:"#", value:"id"},
                    {text:"ISIN", value:"isin"},
                    {text:"Name", value:"name"},
                    {text:"Industry", value:"industry"},
                    {text:"Sector", value:"sector"},
                    {text:"Region", value:"region"},
                    {text:"Country", value:"country"},

                    {text:"Style", value:"style"},
                    {text:"Lynch Category", value:"category_lynch"},

                    {text:"Current Valuation (€)", value:"current_price"},
                    {text:"Market P&L (%)", value:"market_pnl"},
                    {text:"Weight (%)", value:"weight"},
                ]
            }
        },
        data: () => ({
            fundamental_switch:false
        }),
        methods: {
            to_locale(dbl, factor=1.0){
                return Math.round(dbl*100*factor)/100;
            },

        },
    }
</script>
