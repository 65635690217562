var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" Members ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.compo,"items-per-page":20},scopedSlots:_vm._u([{key:"item.pnl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex ",class:[item.pnl>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(Number(item.pnl).toLocaleString())+" € ")])]}},{key:"item.relative_pnl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex ",class:[item.pnl>0.0?'green--text':'red--text']},[_vm._v(" "+_vm._s(Number(item.relative_pnl).toLocaleString())+" % ")])]}},{key:"item.invested_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(Number(item.invested_amount).toLocaleString())+" € ")])]}},{key:"item.current_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(Number(item.current_amount).toLocaleString())+" € ")])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(Number(item.weight).toLocaleString())+" % ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }