<template>
    <v-container transition="scale-transition">
        <v-row class="text-center">
            <v-col
                cols="12"
                md="6"
            >
                <PortfolioMensualPerformance />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <PortfolioYearlyPerformance />
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
            >
              <PortfolioChartVlCard />
                
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
            >
               <PerformancePillarAssets />
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
            >
               <PortfolioDailyVl />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import PortfolioMensualPerformance from './performance/PortfolioMensualPerformance';
import PortfolioYearlyPerformance from './performance/PortfolioYearlyPerformance';
import PortfolioChartVlCard from './performance/PortfolioChartVlCard';
import PerformancePillarAssets from './performance/PerformancePillarAssets';
import PortfolioDailyVl from './performance/PortfolioDailyVl';


  export default {
    name: 'PerformanceDashboard',
    components: {
      PortfolioMensualPerformance,
      PortfolioYearlyPerformance,
      PortfolioChartVlCard,
      PerformancePillarAssets,
      PortfolioDailyVl
    },
    data: () => ({
      
    }),
  }
</script>
