<template>
    <div>
        <v-card elevation="2" outlined>
            <v-card-title>
                <v-col
                    cols="12"
                    md="12"
                >
                    Daily Portfolio values
                </v-col>
            </v-card-title>
            <v-card-text>
                <PortfolioChartVl 
                    :chartData="chartData"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PortfolioChartVl from './PortfolioChartVl'
    export default {
        name: 'PortfolioDailyVl',
        components:{
            PortfolioChartVl
        },
        computed: {
            ...mapGetters('dashboard', {
                performance_daily_ptf:"performance_daily_ptf"
            }),
            labels(){
                let list=[];
                for(let k in this.performance_daily_ptf){
                    let v=this.performance_daily_ptf[k];
                    list.push(v.date.substring(0,16));
                }  
                return list;
            },
            ptf(){
                let list=[];
                for(let k in this.performance_daily_ptf){
                    let v=this.performance_daily_ptf[k];
                    list.push(Math.round(v.vl));
                }  
                return list;
            },
            bmk(){
                let list=[];
                for(let k in this.performance_daily_ptf){
                    let v=this.performance_daily_ptf[k];
                    list.push(Math.round(v.bmk_vl));
                }  
                return list;
            },
            cash(){
                let list=[];
                for(let k in this.performance_daily_ptf){
                    let v=this.performance_daily_ptf[k];
                    list.push(Math.round(v.cash_vl));
                }  
                return list;
            },
            bases(){
                let list=[];
                let init=0.0;
                for(let k in this.performance_daily_ptf){
                    let v=this.performance_daily_ptf[k];
                    if(init==0.0)
                        init=Math.round(v.vl);
                    list.push(init);
                }  
                return list;
            },

            chartData(){
                return {
                    labels: this.labels,
                    datasets: [
                        {
                            label: 'Portfolio',
                            data: this.ptf,
                            fill: false,
                            borderColor: '#2554FF',
                            backgroundColor: '#2554FF',
                            borderWidth: 0,
                        }
                        ,
                        {
                            label: 'Benchmark',
                            data: this.bmk,
                            fill: false,
                            borderColor: '#38761d',
                            backgroundColor: '#38761d',
                            borderWidth: 0,
                        },
                        {
                            label: 'Base',
                            data: this.bases,
                            fill: false,
                            borderColor: '#e30517',
                            backgroundColor: '#e30517',
                            borderWidth: 0,
                        },
                        {
                            label: 'Cash',
                            data: this.cash,
                            fill: false,
                            borderColor: '#00584e',
                            backgroundColor: '#00584e',
                            borderWidth: 0,
                            yAxisID: 'z',
                        },
                    ]
                }
            }
        },
        data: () => ({
            
        }),
        methods:{
            reset_dates(){
                this.$store.commit('dashboard/params_date_reset');
            }
        }
    }
</script>
