<template>
    <v-card elevation="2" outlined>
        <v-card-title>
            <v-col
                cols="9"
            >
                Components
            </v-col>
            <v-col
                cols="3"
            >
                <v-switch
                    v-model="local_switch"
                    :label="`Local performance`"
                ></v-switch>
            </v-col>
             
        </v-card-title>
        <v-card-text>
           
            <v-data-table
                :headers="headers"
                :items="compo"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:item.eod="{ item }">
                    <div
                        :class="[item.eod>0.0?'green--text':'red--text']"
                    >
                        {{ item.eod }} %
                    </div>
                </template>
                <template v-slot:item.eow="{ item }">
                    <div
                        :class="[item.eow>0.0?'green--text':'red--text']"
                    >
                        {{ item.eow }} %
                    </div>
                </template>
                <template v-slot:item.eom="{ item }">
                    <div
                        :class="[item.eom>0.0?'green--text':'red--text']"
                    >
                        {{ item.eom }} %
                    </div>
                </template>
                <template v-slot:item.eoy="{ item }">
                    <div
                        :class="[item.eoy>0.0?'green--text':'red--text']"
                    >
                        {{ item.eoy }} %
                    </div>
                </template>
                <template v-slot:item.m3="{ item }">
                    <div
                        :class="[item.m3>0.0?'green--text':'red--text']"
                    >
                        {{ item.m3 }} %
                    </div>
                </template>
                <template v-slot:item.m6="{ item }">
                    <div
                        :class="[item.m6>0.0?'green--text':'red--text']"
                    >
                        {{ item.m6 }} %
                    </div>
                </template>
                <template v-slot:item.y1="{ item }">
                    <div
                        :class="[item.y1>0.0?'green--text':'red--text']"
                    >
                        {{ item.y1 }} %
                    </div>
                </template>
                
                
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'PerformancePillarAssets',
        computed: {
            ...mapGetters('dashboard', {
                performance_by_pillar:"performance_by_pillar"
            }),
            compo(){
                let i=0;
                return this.performance_by_pillar
                        .map((asset)=>{
                            i=i+1;
                            let col="eur_perf#";
                            if(this.local_switch)
                                col="local_perf#";
                            return {
                                'name':asset.name,
                                'isin': asset.isin,

                                "eod":this.to_locale(asset[col+"D-1"],100),
                                "eow":this.to_locale(asset[col+"EOW"],1),
                                "eom":this.to_locale(asset[col+"EOM"],1),
                                "eoy":this.to_locale(asset[col+"EOY"],1),
                                "m3":this.to_locale(asset[col+"3M"],1),
                                "m6":this.to_locale(asset[col+"6M"],1),
                                "y1":this.to_locale(asset[col+"1Y"],1)
                            }
                        })
            },
            headers(){
                return [
                        {text:"ISIN", value:"isin"},
                        {text:"Name", value:"name"},
                        {text:"D-1 (%)", value:"eod"},
                        {text:"EOW (%)", value:"eow"},
                        {text:"EOM (%)", value:"eom"},
                        {text:"EOY (%)", value:"eoy"},
                        {text:"3M (%)", value:"m3"},
                        {text:"6M (%)", value:"m6"},
                        {text:"1Y (%)", value:"y1"}
                    ]
            }
        },
        data: () => ({
            local_switch:false
        }),
        methods: {
            to_locale(dbl, factor=1.0){
                if(isNaN(dbl))
                    return "-";
                return Math.round(dbl*100*factor)/100;
            }
        },
    }
</script>
