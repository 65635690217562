import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import store from './store'
import MainDashboard from './components/MainDashboard';
import PerformanceDashboard from './components/PerformanceDashboard';
import AssetsDashboard from './components/AssetsDashboard';
import ParticipantsDashboard from './components/ParticipantsDashboard';


const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'MainDashboard',
      component: MainDashboard
    },
    {
      path: '/dashboard',
      name: 'MainDashboard',
      component: MainDashboard
    },
    {
      path: '/performance',
      name: 'PerformanceDashboard',
      component: PerformanceDashboard
    },
    {
      path: '/assets',
      name: 'AssetsDashboard',
      component: AssetsDashboard
    },
    {
      path: '/participants',
      name: 'ParticipantsDashboard',
      component: ParticipantsDashboard
    }
  ]
})

Vue.config.productionTip = false
Vue.use(VueRouter);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
