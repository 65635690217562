<template>
    <v-container 
        transition="scale-transition"
        fluid>
        <v-row class="text-center">
            <v-col
                cols="12"
                md="6"
            >
                <PortfolioState />
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <PortfolioPerforanceHighlights />
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
            >
                <PortfolioChartVlCard />
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
            >
                <PortfolioAssets />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PortfolioState from './dashboard/PortfolioState';
import PortfolioChartVlCard from './dashboard/PortfolioChartVlCard';
import PortfolioAssets from './dashboard/PortfolioAssets';
import PortfolioPerforanceHighlights from './dashboard/PortfolioPerforanceHighlights';

  export default {
    name: 'MainDashboard',
    components: {
        PortfolioState,
        PortfolioChartVlCard,
        PortfolioAssets,
        PortfolioPerforanceHighlights
    },
    data: () => ({
      
    }),
  }
</script>
