<script>
  import { Pie, mixins } from 'vue-chartjs'

  export default {
    extends: Pie,
    data () {
      return {
        options: {
            elements: {
                point:{
                    radius: 0
                }
            },
            scales: {
            },
            legend: {
                display: true
            },
            responsive: true,
            maintainAspectRatio: false
            }
      }
    },
    props: ["chartData"],
    mixins: [mixins.reactiveProp],
    mounted () {
        this.renderChart(this.chartData, this.options)
    },
    
  }
</script>