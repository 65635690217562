<script>
  import { Line } from 'vue-chartjs'
  import { mapGetters } from 'vuex'

  export default {
    extends: Line,
    computed: {
        ...mapGetters('dashboard', {
            vl_hist_ptf:"vl_hist_ptf"
        }),
        labels(){
            let list=[];
            for(let k in this.vl_hist_ptf){
                let v=this.vl_hist_ptf[k];
                list.push(v.date);
            }  
            return list;
        },
        ptf_vls(){
            let list=[];
            for(let k in this.vl_hist_ptf){
                let v=this.vl_hist_ptf[k];
                list.push(v.vl);
            }  
            return list;
        },
        bases(){
            let list=[];
            for(let k in this.vl_hist_ptf){
                let v=this.vl_hist_ptf[k];
                list.push(v.base);
            }  
            return list;
        },
        chartData(){
            return {
                labels: this.labels,
                datasets: [
                    {
                    label: 'Portfolio Vl',
                    data: this.ptf_vls,
                    fill: false,
                    borderColor: '#2554FF',
                    backgroundColor: '#2554FF',
                    borderWidth: 0
                    },
                    {
                    label: 'Base',
                    data: this.bases,
                    fill: false,
                    borderColor: '#e30517',
                    backgroundColor: '#e30517',
                    borderWidth: 0
                    }
                ]
            }
        }
    },
    data () {
      return {
        options: {
            elements: {
                point:{
                    radius: 0
                }
            },
            scales: {
                yAxes: [{
                ticks: {
                    beginAtZero: false
                },
                gridLines: {
                    display: true
                }
                }],
                xAxes: [ {
                gridLines: {
                    display: false
                }
                }]
            },
            legend: {
                display: true
            },
            responsive: true,
            maintainAspectRatio: false
            }
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }
</script>