<template>
    <div>
        <v-card elevation="2" outlined>
            <v-card-title>
                <v-col
                    cols="12"
                    md="6"
                >
                    Value of the portfolio and the benchmark
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                >
                    <v-select
                        v-model="begin_date"
                        :items="vl_hist_ptf_bmk_all_dates"
                        :rules="[v => !!v || 'Date is required']"
                        label="Begin Date"
                        required
                    ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                >
                    <v-select
                        v-model="end_date"
                        :items="vl_hist_ptf_bmk_all_dates"
                        :rules="[v => !!v || 'Date is required']"
                        label="End Date"
                        required
                    ></v-select>
                </v-col>
                <v-col 
                    cols="12"
                    md="2"
                >
                    <v-btn
                        @click="reset_dates();"
                        block
                        color="primary"
                        elevation="2"
                        large
                    >Reset</v-btn>
                </v-col>
                
            </v-card-title>
            <v-card-text>
                <PortfolioChartVl 
                    :chartData="chartData"
                />
            </v-card-text>
        </v-card>
        <v-card elevation="2" outlined>
            <v-card-title>
                <v-col
                    cols="12"
                    md="6"
                >
                    Value of the alpha
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                >
                    <v-select
                        v-model="begin_date"
                        :items="vl_hist_ptf_bmk_all_dates"
                        :rules="[v => !!v || 'Date is required']"
                        label="Begin Date"
                        required
                    ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                >
                    <v-select
                        v-model="end_date"
                        :items="vl_hist_ptf_bmk_all_dates"
                        :rules="[v => !!v || 'Date is required']"
                        label="End Date"
                        required
                    ></v-select>
                </v-col>
                <v-col 
                    cols="12"
                    md="2"
                >
                    <v-btn
                        @click="reset_dates();"
                        block
                        color="primary"
                        elevation="2"
                        large
                    >Reset</v-btn>
                </v-col>
                
            </v-card-title>
            <v-card-text>
                <PortfolioChartVl 
                    :chartData="chartDataRelative"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PortfolioChartVl from './PortfolioChartVl'
    export default {
        name: 'PortfolioChartVlCard',
        components:{
            PortfolioChartVl
        },
        computed: {
            ...mapGetters('dashboard', {
                vl_hist_ptf_bmk:"vl_hist_ptf_bmk",
                params_begin_date:"params_begin_date",
                params_end_date:"params_end_date",
                vl_hist_ptf_bmk_all_dates:"vl_hist_ptf_bmk_all_dates"
            }),
            labels(){
                let list=[];
                for(let k in this.vl_hist_ptf_bmk){
                    let v=this.vl_hist_ptf_bmk[k];
                    list.push(v.date);
                }  
                return list;
            },
            begin_date:{
                get(){
                    return this.params_begin_date;
                },
                set(val){
                    this.$store.commit('dashboard/params_begin_date', {
                        begin_date: val
                    })
                }
            },
            end_date:{
                get(){
                    return this.params_end_date;
                },
                set(val){
                    this.$store.commit('dashboard/params_end_date', {
                        end_date: val
                    })
                }
            },
            ptf_vls(){
                let list=[];
                let init_value=0.0;
                for(let k in this.vl_hist_ptf_bmk){
                    let v=this.vl_hist_ptf_bmk[k];
                    if(init_value==0.0)
                        init_value=v.ptf;
                    list.push(Math.round(v.ptf/init_value*1000.0*100)/100.0);
                }  
                return list;
            },
            bmk_vls(){
                let list=[];
                let init_value=0.0;
                for(let k in this.vl_hist_ptf_bmk){
                    let v=this.vl_hist_ptf_bmk[k];
                    if(init_value==0.0)
                        init_value=v.bmk;
                    list.push(Math.round(v.bmk/init_value*1000.0*100)/100.0);
                }  
                return list;
            },
            alpha_vls(){
                let list=[];
                let init_value_ptf=0.0;
                let init_value_bmk=0.0;

                for(let k in this.vl_hist_ptf_bmk){
                    let v=this.vl_hist_ptf_bmk[k];
                    if(init_value_ptf==0.0)
                        init_value_ptf=v.ptf;
                    if(init_value_bmk==0.0)
                        init_value_bmk=v.bmk;
                    let alpha=(v.ptf/init_value_ptf-1)-(v.bmk/init_value_bmk-1)
                    list.push(Math.round(alpha*100.0*100)/100.0);
                }  
                return list;
            },
            bases(){
                let list=[];
                for(let k in this.vl_hist_ptf_bmk){
                    let v=this.vl_hist_ptf_bmk[k];
                    list.push(v.base);
                }  
                return list;
            },
            chartData(){
                return {
                    labels: this.labels,
                    datasets: [
                        {
                        label: 'Portfolio Vl',
                        data: this.ptf_vls,
                        fill: false,
                        borderColor: '#2554FF',
                        backgroundColor: '#2554FF',
                        borderWidth: 0
                        },
                        {
                        label: 'Benchmark Vl',
                        data: this.bmk_vls,
                        fill: false,
                        borderColor: '#38761d',
                        backgroundColor: '#38761d',
                        borderWidth: 0
                        },
                        {
                        label: 'Base',
                        data: this.bases,
                        fill: false,
                        borderColor: '#e30517',
                        backgroundColor: '#e30517',
                        borderWidth: 0
                        }
                    ]
                }
            },
            chartDataRelative(){
                return {
                    labels: this.labels,
                    datasets: [
                        {
                        label: 'Alpha',
                        data: this.alpha_vls,
                        fill: false,
                        borderColor: '#2554FF',
                        backgroundColor: '#2554FF',
                        borderWidth: 0
                        },
                        {
                        label: 'Base',
                        data: 0,
                        fill: false,
                        borderColor: '#e30517',
                        backgroundColor: '#e30517',
                        borderWidth: 0
                        }
                    ]
                }
            }
        },
        data: () => ({
            
        }),
        methods:{
            reset_dates(){
                this.$store.commit('dashboard/params_date_reset');
            }
        }
    }
</script>
