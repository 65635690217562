<template>
    <v-container transition="scale-transition">
        <v-row>
            <v-col
                cols="12"
            >
              <PortfolioAssets />
                
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="6"
            >
                <PieCard breakdown_type="region" />
               
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <PieCard breakdown_type="country" />
               
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="6"
            >
                <PieCard breakdown_type="sector" />
               
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <PieCard breakdown_type="industry" />
               
            </v-col>
        </v-row>

         <v-row>
            <v-col
                cols="12"
                md="6"
            >
                <PieCard breakdown_type="style" />
               
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <PieCard breakdown_type="category" />
               
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import PortfolioAssets from './assets/PortfolioAssets';
    import PieCard from './assets/PieCard';


  export default {
    name: 'AssetsDashboard',
    components: {
        PieCard,
        PortfolioAssets
    },
    data: () => ({
      
    }),
  }
</script>
