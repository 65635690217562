
//import json from '../../assets/current_portfolio.json'
//import compo from '../../assets/fighting_bulls'
//import risk_perf from '../../assets/fighting_bulls_risk_perf'

import axios from 'axios';

// initial state
const state = () => ({
    is_init:0,
    data:{},
    compo:{},
    risk_perf:{},
    params:{
        begin_date:"",
        end_date:"",
        clipped:false,
        drawer:false,
        is_identified: sessionStorage.identification
    },
    tabs:[
        {
            'link':"/dashboard",
            "name": "DashBoard",
            "icon": "mdi-view-dashboard",
            'is_header':true
        },
        {
            'link':"/performance",
            "name": "Perforance",
            "icon": "mdi-chart-line",
            'is_header':true
        },
        {
            'link':"/assets",
            "name": "Assets",
            "icon": "mdi-table-headers-eye",
            'is_header':true
        },
        {
            'link':"/participants",
            "name": "Membres",
            "icon": "mdi-account-multiple",
            'is_header':true
        }
  ]
})

// getters
const getters = {
    get_params:(state)=>{
        return state.params;
    },
    params_tabs:(state)=>{
        return state.tabs;
    },
    is_connected:(state)=>{
        if(state.is_init>=2) return true;
        return false;
    },
    is_identified:(state)=>{
        if(state.params.is_identified=="Fighting2022")
            return true;
        return false;
    },
    members:(state)=>{
        return state.compo.members
    },
    compo_last_date:(state)=>{
        return state.compo.current_composition[0].value_date
    },
    compo_ptf_value:(state)=>{
        let nb=state.compo.portfolio_amount.length;
        return state.compo.portfolio_amount[nb-1].ptf_amount
    },
    compo_cash_value:(state)=>{
        return state.compo.current_composition[0].cash_value
    },
    compo_ptf_value_previous:(state)=>{
        let nb=state.compo.portfolio_amount.length;
        return state.compo.portfolio_amount[nb-2].ptf_amount
    },
    compo_cash_value_previous:(state)=>{
        let nb=state.compo.portfolio_amount.length;
        return state.compo.portfolio_amount[nb-2].cash_amount;
    },
    compo_ptf_nb_assets:(state)=>{
        return state.compo.current_composition.length;
    },
    vl_last_ptf:(state)=>{
        return Math.round((state.compo.portfolio_vl.slice(-1)[0].vl/1000-1)*10000)/100;
    },
    moves_invested_amount:(state)=>{
        let virement=state.compo.portfolio_moves
                        .filter((item)=>{
                            return item.type=="Virement";
                        })
                        .map((item)=>{
                            return item.amount;
                        })
                        .reduce((prevValue,val)=>{
                            return prevValue+val;
                        });
        return virement;
    },
    perf_yearly:(state)=>{
        return state.risk_perf.yearly_perf;
    },
    perf_monthly_last_4:(state)=>{
        return state.risk_perf.monthly_perf.slice(-4);
    },
    perf_highlights:(state)=>{
        return state.risk_perf.perf_hightlights;
    },
    vl_hist_ptf:(state)=>{
        return state.compo.portfolio_vl
            .map((item)=>{
                return {
                    "date":item.date.split(" ")[0],
                    "vl":Math.round(item.vl*10)/10,
                    "base":1000.0
                };
            });
    },
    vl_hist_ptf_bmk:(state)=>{
        if(state.params.begin_date=="")
            state.params.begin_date=state.risk_perf.vl[0].date;
        if(state.params.end_date=="")
            state.params.end_date=state.risk_perf.vl.slice(-1)[0].date;

        return state.risk_perf.vl
            .filter((item)=>{
                let ok=true;
                if(item.date<state.params.begin_date){
                    ok=false;
                }
                if(item.date>state.params.end_date)
                    ok=false;
                return ok;
            })
            .map((item)=>{
                return {
                    "date":item.date,
                    "ptf":Math.round(item.ptf_vl*10)/10,
                    "bmk":Math.round(item.bmk_vl*10)/10,
                    "alpha":Math.round(item.alpha*1000)/10,
                    "base":1000.0
                };
            });
    },
    vl_hist_ptf_bmk_all_dates:(state)=>{
        return state.risk_perf.vl
            .map((item)=>{
                return item.date;
            });
    },
    moves_dividends:(state)=>{
        /*const groupBy = (arr, key) => {
            const initialValue = {};
            return arr.reduce((acc, cval) => {
              const myAttribute = cval[key];
              acc[myAttribute] = [...(acc[myAttribute] || []), cval]
              return acc;
            }, initialValue);
        };*/
        const groupBy = (arr, key, value) => {
            const initialValue = {};
            return arr.reduce((acc, cval) => {
                const myAttribute = cval[key];
                if(acc[myAttribute]!=undefined)
                    acc[myAttribute] =acc[myAttribute] +cval[value];
                else
                    acc[myAttribute] =+cval[value];
                return acc;
            }, initialValue);
        };
        let divs=state.compo.portfolio_moves
                        .filter((item)=>{
                            return item.type=="Coupon";
                        })
                        .map((item)=>{
                            return {
                                'amount':item.amount,
                                'isin': item.isin
                            };
                        });
        divs=groupBy(divs, "isin", "amount");
        return divs;
    },
    compo_current:(state)=>{
        return state.compo.current_composition
                    .sort((a,b)=>{
                        return a.valo<b.valo?1:-1;
                    })
    },
    compo_descriptives:(state)=>{
        return state.compo.descriptives;
    },
    compo_other_descriptives:(state)=>{
        return state.compo.other_descriptives;
    },
    params_begin_date:(state)=>{
        if(state.params.begin_date=="")
            return state.risk_perf.vl[0].date;
        return state.params.begin_date;
    },
    params_end_date:(state)=>{
        if(state.params.end_date=="")
            return state.risk_perf.vl.slice(-1)[0].date;
        return state.params.end_date;
    },
    performance_by_pillar:(state)=>{
        return state.risk_perf.perf_by_pillar;
    },
    performance_daily_ptf:(state)=>{    
        let first_date=state.compo
                        .daily_ptf
                        .filter((item)=>{
                            return item.bmk_vl!=0.0;
                        })
                        .slice(-1)[0];
        let first_bmk=first_date["bmk_vl"];
        let first_ptf=first_date["vl"];
        first_date=first_date["date"];
        var last_bmk=first_bmk;

        return state.compo
                    .daily_ptf
                    .filter((item)=>{
                        return item.date>=first_date;
                    })
                    .sort((a,b)=>a.date>b.date?1:-1)
                    .map((item)=>{
                        if(item["bmk_vl"]!=0.0)
                            last_bmk=item["bmk_vl"]/first_bmk*10000.0;
                        return {
                            'date':item["date"],
                            'vl': item["vl"]/first_ptf*10000.0,
                            'bmk_vl': last_bmk,
                            'cash_vl': item["cash_vl"]
                        }
                    });
    }




}

// actions
const actions = {

}

// mutations
const mutations = {
    params_begin_date (state, payload) {
        state.params.begin_date = payload.begin_date;
    },
    params_end_date (state, payload) {
        state.params.end_date = payload.end_date;
    },
    params_date_reset(state){
        state.params.begin_date=state.risk_perf.vl[0].date;
        state.params.end_date=state.risk_perf.vl.slice(-1)[0].date;
    },
    set_params(state, payload){
        let key=payload.key;
        let value=payload.value;
        state.params[key]=value
    },
    connect_to_dashboard(state){

        state.is_init=0;
        axios
        .get('https://www.fightingbulls.davoust.io/fighting_bulls')
        .then(response => {
            let compo=response.data;
            if (typeof compo === 'string' || compo instanceof String)
                state.compo=JSON.parse(compo.replace(/\bNaN\b/g, "null"))
            else
                state.compo=compo;
            
            state.is_init++;
        })

        axios
        .get('https://www.fightingbulls.davoust.io/fighting_bulls_risk_perf')
        .then(response => {
            state.risk_perf=response.data;
            state.is_init++;
        })

    },
    make_identification (state, payload) {
        state.params.is_identified = payload.is_identified;
        sessionStorage.identification=state.params.is_identified;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}