<template>
    <v-container transition="scale-transition">
        <v-row>
            <v-col
                cols="12"
            >
              <UsersList />
                
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>

    import UsersList from './users/UsersList';


  export default {
    name: 'ParticipantsDashboard',
    components: {
        UsersList
    },
    data: () => ({
      
    }),
  }
</script>
