<template>
  <v-app-bar 
    app
    color="indigo"
    booted="false"
    >
    
    <v-btn 
      icon
      color="white"
      @click.stop="drawer = !drawer">
      <v-icon>mdi-reorder-horizontal</v-icon>
    </v-btn>

    <v-toolbar-title>
      <Logo 
        custom_style="color: white"
      />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="is_identified">
    <v-btn 
      v-for="tab in params_tabs"
      :key="tab.name"
      icon
      color="white"
      :to="tab.link">
      <v-icon>{{tab.icon}}</v-icon>
    </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'


  export default {
    name: 'AppBar',
    components: {
      Logo
    },
    data: () => ({
      list_tab:[
      ]
    }),
    computed: {
      ...mapGetters('dashboard', {
          is_connected:"is_connected",
          get_params:"get_params",
          params_tabs:"params_tabs",
          is_identified:"is_identified"
      }),
      clipped:{
        get(){
            return this.get_params.clipped;
        },
        set(val){
            this.$store.commit('dashboard/set_params', {
                key:"clipped",
                value: val
            })
        }
      },
      drawer:{
        get(){
            return this.get_params.drawer;
        },
        set(val){
            this.$store.commit('dashboard/set_params', {
                key:"drawer",
                value: val
            })
        }
      }
    },
  }
</script>
